import React from 'react';

import { Page, THEME_DARK } from '../layout';
import {
	Hours,
	Address,
	HoursAlternate,
	AddressAlternate,
	Phone,
	Email,
} from '../contacts';
import styles from './contact.module.scss';

export const Contact = () => (
	<Page
		title="Kontakt"
		theme={THEME_DARK}
		subtitle="Vereinbaren Sie Ihren Termin noch heute">
		<div className={styles.wrapper}>
			<div className={styles.item}>
				<h4 className={styles.title}>Kontakt</h4>
				<div className={styles.contacts}>
					<div className={styles.contact}>
						<Phone />
					</div>
					<div className={styles.contact}>
						<Email />
					</div>
				</div>
			</div>

			<div className={styles.item}>
				<h4 className={styles.title}>Standort Fulpmes</h4>
				<div className={styles.contacts}>
					<div className={styles.contact}>
						<Hours />
					</div>
					<div className={styles.contact}>
						<Address />
					</div>
				</div>
			</div>
		</div>
	</Page>
);
